<template>
  <div id="main-container" class="column">
    <div class="card">
      <header class="card-header is-flex is-flex--center is-flex--space-between">
          <p class="card-header-title is-size-5 has-text-grey">Lista de Reglas</p>
          <a class="card-header-icon" aria-label="more options">
            <router-link :to="{name: 'ruleCreate'}" class="button is-accent is-small">
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>Agregar Regla</span>
            </router-link>
          </a>
      </header>

      <div class="card-content">
        <div class="columns is-desktop">
          <div class="column">
            <cli-input v-model="filters.clientId"></cli-input>
          </div>
          <div class="column">
            <plat-input v-model="filters.platformId"></plat-input>
          </div>
          <div class="column">
            <cluster-input v-model="filters.clusterId"></cluster-input>
          </div>
          <div class="column">
            <segment-input v-model="filters.segmentType"></segment-input>
          </div>
          <div :style="{paddingRight: 0}" class="column is-1" >
            <b-button :style="{ marginTop: '30px'}" type="button" expanded class="button is-accent is-medium is-pulled-right"  @click="handleSubmit">Buscar</b-button>
          </div>
        </div>
          <hr />
          <b-table
            :loading="requestPricings"
            :data="pricings.length ? pricings : []"
            :paginated="true"
            :sort-icon="'chevron-up'"
            :sort-icon-size="'is-small'"
            striped
          >
        <template slot-scope="props">
          <b-table-column field="idRule" sortable label="ID" width="40">{{ props.row.idRule }}</b-table-column>
          <b-table-column field="client.name" label="Client" sortable centered >{{ props.row.client.name }}</b-table-column>
          <b-table-column field="platform.name" label="Platform" sortable centered>{{ props.row.platform.name }}</b-table-column>
          <!-- <b-table-column field="cluster.name" label="Cluster" sortable centered>{{ props.row.cluster.name + '-' + props.row.cluster.name_parent + '-' + props.row.cluster.name_client }}</b-table-column> -->
          <b-table-column field="cluster.name" label="Cluster" sortable centered>{{ getClusterParent(props.row.cluster) }}</b-table-column>
          <b-table-column
            field="segmentType.name"
            label="Segment Type"
            sortable
            centered
          >{{ props.row.segmentType.name}}</b-table-column>
          <b-table-column field="price" label="Price" sortable centered>{{ props.row.price }}</b-table-column>
          <b-table-column field="actions" label="Acciones" centered>
            <a
              class="has-text-info"
              @click="goToPriceEdition(props.row.idRule, props.row.client.name)"
            >
              <b-tooltip
                :label="$t('tables.edit')"
                position="is-top"
                type="is-info"
                size="is-small"
                animated
                square
              >
                <b-icon class="has-padding" pack="fas" icon="edit" size="is-small"></b-icon>
              </b-tooltip>
            </a>
            <a
              class="has-text-info"
              @click="deleteRule(props.row.idRule, props.row.client.name)"
            >
              <b-tooltip
                :label="`Borrar Regla`"
                position="is-top"
                type="is-info"
                size="is-small"
                animated
                square
              >
                <b-icon class="has-padding" pack="fas" icon="trash" size="is-small" type="is-primary"></b-icon>
              </b-tooltip>
            </a>
          </b-table-column>
        </template>
        <template slot="empty"><section class="section">
              <div class="content has-text-grey has-text-centered">
                <p v-show="!requestPricesError && !requestPricings">
                  <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
                </p>
                <p
                  v-show="!requestPricesError && !requestPricings"
                >{{ $t('warnings.accountNoData') }}</p>
              </div>
              <div
                v-show="requestPricesError"
                class="content has-text-danger has-text-centered"
              >
                <p>
                  <span class="icon is-large">
                    <i class="far fa-frown fa-3x"></i>
                  </span>
                </p>
                <p>{{ $t('errors.requestError')}}</p>
              </div>
            </section></template>
      </b-table>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CliInput from './customInputs/clientsInput'
import PlatInput from './customInputs/platformsInput'
import ClusterInput from './customInputs/clusterInput'
import SegmentInput from './customInputs/segmentTypesInput'
import InputType from '../../common/widgets/rule-types/InputRules'

export default {
  components: {
    'cli-input': CliInput,
    'plat-input': PlatInput,
    'cluster-input': ClusterInput,
    'segment-input': SegmentInput,
    'types-input': InputType
  },
  data() {
    return {
      filters: {
        clientId: [],
        platformId: [],
        clusterId: [],
        segmentType: []
      }
    }
  },
  computed: {
    ...mapGetters(['pricings', 'requestPricings', 'tokenValid', 'requestPricesError'])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  mounted() {
    this.getPricingList()
    this.getClients()
    this.getPlatforms()
    this.getClusters()
    this.getSegmentTypes()
  },
  methods: {
    ...mapActions(['getPricingList', 'getClients', 'getPlatforms', 'getClusters', 'getSegmentTypes', 'deleteRuleAction']),
    goToPriceEdition(idRow, nameRow) {
      this.$buefy.toast.open({
        message: `#${idRow} rule will be edited!`,
        type: 'is-success'
      })
      this.$router.push({ name: 'pricingRules', params: { id: `${idRow}` } })
    },
    handleSubmit() {
      this.getPricingList(this.filters)
    },
    getClusterParent(cluster) {
      if (cluster.name_parent === null) {
        return cluster.name + ' - ' + cluster.name_client
      } else if (!cluster.name_parent) {
        return cluster.name
      } else {
        return cluster.name + ' - ' + cluster.name_parent + ' - ' + cluster.name_client
      }
    },
    deleteRule(idRow, nameRow) {
      this.$buefy.dialog.confirm({
        title: 'Confirmar eliminación',
        message: `¿Estás seguro de que deseas eliminar la regla ${nameRow}?`,
        confirmText: 'Sí',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          try {
            this.deleteRuleAction({ idRule: idRow, nameRow })
            this.$buefy.toast.open({
              message: `¡Regla ${nameRow} eliminada!`,
              type: 'is-success'
            })
          } catch (error) {
            console.error('Error al eliminar la regla:', error)
            this.$buefy.toast.open({
              message: 'Error al eliminar la regla. Por favor, inténtalo de nuevo.',
              type: 'is-danger'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  border-radius: 4px;
}
</style>
